import { Buffer } from 'buffer';
import { useSnackbar } from 'notistack';
import { deflate, inflate } from 'pako';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useFetch from 'use-http';
import {
  apiBridges,
  apiCollaborators,
  apiInspections,
  apiMedia,
} from 'constants/api';
import { InspectionMainTab, InspectionStatus } from 'constants/inspections';
import { STRUCTURES_PATHS } from 'constants/paths';
import { PermissionType } from 'constants/permissions';
import usePrevious from 'hooks/usePrevious';
import usePromises from 'hooks/usePromises';
import {
  useAuthSelector,
  useGlobalSelector,
  useImagesStore,
  useInspectionSelector,
} from 'stores';
import { mappingInAll } from './utils/mappingInAll';
/* import { mappingDataInL0 } from './utils/mappingInL0';
import { mappingDataInL1 } from './utils/mappingInL1'; */
import { mappingDataOutL0 } from './utils/mappingOutL0';
import { mappingDataOutL1 } from './utils/mappingOutL1';

const InspectionDisabledMapByPermission = {
  // this object map the value of the permission of the user
  // on the given inspection, and sets the "inspectionDisabled" store
  [PermissionType.CanView]: true,
  [PermissionType.CanEdit]: false,
  [PermissionType.CanViewByInspectionReference]: true,
  [PermissionType.NoPermission]: true,
};

const CanShareMap = {
  // this object maps the possibile actions on the sharing popover
  [InspectionStatus.NEW]: {
    [PermissionType.CanView]: false,
    [PermissionType.CanEdit]: true,
    [PermissionType.CanViewByInspectionReference]: false,
    [PermissionType.NoPermission]: false,
  },
  [InspectionStatus.ONGOING]: {
    [PermissionType.CanView]: false,
    [PermissionType.CanEdit]: true,
    [PermissionType.CanViewByInspectionReference]: false,
    [PermissionType.NoPermission]: false,
  },
  [InspectionStatus.OnGoing]: {
    [PermissionType.CanView]: false,
    [PermissionType.CanEdit]: true,
    [PermissionType.CanViewByInspectionReference]: false,
    [PermissionType.NoPermission]: false,
  },
  [InspectionStatus.Archived]: {
    [PermissionType.CanView]: false,
    [PermissionType.CanEdit]: false,
    [PermissionType.CanViewByInspectionReference]: false,
    [PermissionType.NoPermission]: false,
  },
  [InspectionStatus.Delivered]: {
    [PermissionType.CanView]: false,
    [PermissionType.CanEdit]: false,
    [PermissionType.CanViewByInspectionReference]: false,
    [PermissionType.NoPermission]: false,
  },
  [InspectionStatus.OnGoingDelivery]: {
    [PermissionType.CanView]: false,
    [PermissionType.CanEdit]: false,
    [PermissionType.CanViewByInspectionReference]: false,
    [PermissionType.NoPermission]: false,
  },
};

const useInspection = ({ inspectionId, structureId, openSendArchive }) => {
  const { put, get, error } = useFetch();
  const { enqueueSnackbar } = useSnackbar();
  const { showLoader, hideLoader, activeTabLevel } = useGlobalSelector();
  const prevActive = usePrevious(activeTabLevel);
  const navigate = useNavigate();
  const { setPromises } = usePromises();

  const { setInspectionDisabled } = useInspectionSelector();
  const { user } = useAuthSelector();

  const [structure, setStructure] = useState();
  const [inspection, setInspection] = useState();
  const [collaborators, setCollaborators] = useState([]);
  const [canShare, setCanShare] = useState(false);
  const [isStructureOwner, setIsStructureOwner] = useState(false);
  const [isInspectionOwner, setIsInspectionOwner] = useState(false);
  const [currentInspectionStatus, setCurrentInspectionStatus] = useState();
  const [inspectionStatus, setInspectionStatus] = useState();
  const [currentInspectionMyPermissions, setCurrentInspectionMyPermissions] =
    useState();
  const [webCompletionPercentage, setWebCompletionPercentage] = useState(0);
  const [appCompletionPercentage, setAppCompletionPercentage] = useState(0);
  const [accessExpirationDate, setAccessExpirationDate] = useState(null);

  const defaultValues = {};
  const methods = useForm({
    defaultValues,
  });

  /* START - Detail logic */

  // Get Detail of inspection
  const handleDetail = async () => {
    if (
      prevActive !== activeTabLevel &&
      (activeTabLevel === 1 || activeTabLevel === 2)
    ) {
      let result = '';
      try {
        showLoader();
        result = await get(`${apiBridges.INSPECTIONS}/${inspectionId}`);

        // unzip (inflate) diagnosis
        const inflatedDiagnosis = inflate(
          Buffer.from(result.diagnosis, 'base64'),
          {
            to: 'string',
          }
        );
        const parsedInflatedDiagnosis = JSON.parse(inflatedDiagnosis);
        result.diagnosis = parsedInflatedDiagnosis;

        let formattedData = {};
        if (activeTabLevel === InspectionMainTab.L0_CENSIMENTO_ANAGRAFICA) {
          //formattedData = mappingDataInL0(result);
          formattedData = mappingInAll(result);
        }
        if (activeTabLevel === InspectionMainTab.L1_ISPEZIONI_PRELIMINARI) {
          //formattedData = mappingDataInL1(result);
          formattedData = mappingInAll(result);
        }

        setStructure(result?.structure);
        setInspection(result);
        reset(formattedData);

        const resultMedia = await get(
          `${apiMedia.LIST}/${inspectionId}/images`
        );
        if (resultMedia) {
          useImagesStore.setState(() => ({
            images: resultMedia,
          }));
        }
      } catch (err) {
        navigate(STRUCTURES_PATHS.STRUCTURES_LIST);
        console.error('Error in read diagnosis: ', err);
        return enqueueSnackbar(
          `Si è verificato un errore nella lettura dell'ispezione`,
          {
            variant: 'error',
          }
        );
      } finally {
        hideLoader();
      }
    }
  };

  //Const data Structure
  const titlePage = `Attività ${structure?.name || ''}` || '-';
  const city = structure?.province?.name;
  const council = structure?.council?.name;
  const address =
    structure?.address || structure?.province?.code || city || council
      ? `${structure?.address ? structure.address + ',' : ''} ${council} (${
          structure?.province?.code
        })`
      : '-';
  const internalCode = structure?.internalCode || '-';
  const owner = structure?.owner?.email || '-';
  const infoData = {
    street: address || '-',
    city: city ? `Comune di ${council}` : '-',
    code: internalCode ? `ID ${internalCode}` : '-',
    owner,
  };

  useEffect(() => {
    inspectionId && handleDetail().catch(console.error);
  }, [inspectionId, activeTabLevel]);

  /* END - Detail logic */

  /* START - RHF logic */

  const { handleSubmit, reset, formState } = methods;

  const onSubmit = useCallback(
    async submittedData => {
      //TO DO: gestire salvataggio L0/L1
      let body = '';
      if (activeTabLevel === InspectionMainTab.L0_CENSIMENTO_ANAGRAFICA) {
        body = {
          diagnosis: mappingDataOutL0(submittedData),
          status: inspectionStatus,
          completionPercentage: webCompletionPercentage,
          completionPercentageApp: appCompletionPercentage,
        };
      }

      if (activeTabLevel === InspectionMainTab.L1_ISPEZIONI_PRELIMINARI) {
        body = {
          diagnosis: mappingDataOutL1(submittedData),
          status: inspectionStatus,
          completionPercentage: webCompletionPercentage,
          completionPercentageApp: appCompletionPercentage,
        };
      }

      try {
        showLoader();
        // zip (deflate) diagnosis
        const compressedBody = deflate(JSON.stringify(body));
        const compressedBodyBuffer = Buffer.from(compressedBody);
        const compressedBodyBufferString =
          compressedBodyBuffer.toString('base64');

        body = { zip: compressedBodyBufferString };
        await put(`${apiBridges.INSPECTIONS}/${inspectionId}/diagnosis`, body);
        const result = await get(`${apiMedia.LIST}/${inspectionId}/images`);
        if (result) {
          useImagesStore.setState(() => ({
            images: result,
          }));
        }
        return enqueueSnackbar(`Salvataggio effettuato`, {
          variant: 'success',
        });
      } catch (err) {
        console.error('Error in save diagnosis: ', err);
        return enqueueSnackbar(
          `Si è verificato un errore nel salvataggio dell'ispezione`,
          {
            variant: 'error',
          }
        );
      } finally {
        hideLoader();
      }
    },
    [webCompletionPercentage, appCompletionPercentage, activeTabLevel]
  );

  /* END - RHF logic */

  /* START - Permissions logic */

  // error in case of wrong permissions or other
  useEffect(() => {
    if (error?.message?.includes('500 - Internal Server Error')) {
      return;
    } else {
      error && navigate(STRUCTURES_PATHS.STRUCTURES_LIST);
    }
  }, [error]);

  const handleCollaborators = async () => {
    const res = await get(
      `${apiCollaborators.ROOT}/inspection/${inspectionId}`
    );
    setCollaborators(
      res
        .map(el => {
          return {
            ...el,
            collaborationInfo: {
              ...el?.sharedInspectionsWithMe[0],
              type: el?.collaboratorOf[0]?.type,
            },
          };
        })
        .filter(
          el => el.collaborationInfo?.permission === PermissionType.CanEdit
        )
    );
  };
  const handleMyPermissions = async () => {
    const res = await get(
      `${apiInspections.ROOT}/${inspectionId}${apiInspections.MY_PERMISSIONS}`
    );
    if (!res) {
      setCurrentInspectionMyPermissions(true);
      setCanShare(false);
    } else {
      if (res.accessExpiration) {
        setAccessExpirationDate(res.accessExpiration);
      }
      setCurrentInspectionMyPermissions(
        InspectionDisabledMapByPermission[res?.permission]
      );
      setCanShare(CanShareMap[inspection?.status][res?.permission]);
    }
  };

  const shareInspection = () => {
    handleCollaborators();
  };

  useEffect(() => {
    if (inspectionId && inspection) {
      setPromises([handleCollaborators(), handleMyPermissions()]);
    }
  }, [inspectionId, inspection]);

  useEffect(() => {
    if (user && structure && inspection) {
      if (
        user.id === structure?.owner?.id ||
        user.parent?.id === structure?.owner?.id
      ) {
        setIsStructureOwner(true);
      }
      if (
        user.id === inspection?.inspectionOwnerId ||
        user.parent?.id === inspection?.inspectionOwnerId
      ) {
        setIsInspectionOwner(true);
      }
      setCurrentInspectionStatus(
        inspection?.status === InspectionStatus.Archived ||
          inspection?.status === InspectionStatus.OnGoingDelivery ||
          inspection?.status === InspectionStatus.Delivered ||
          (inspection?.lockedAt && inspection?.lockedBy?.id !== user?.id)
      );
      setInspectionStatus(inspection?.status);
    }
  }, [user, structure, inspection]);

  useEffect(() => {
    if (currentInspectionStatus) {
      return setInspectionDisabled(currentInspectionStatus);
    }
    setInspectionDisabled(currentInspectionMyPermissions);
  }, [currentInspectionMyPermissions, currentInspectionStatus]);

  useEffect(() => {
    const handleStructureDetail = async () => {
      try {
        showLoader();
        const result = await get(`${apiBridges.ROOT}/${structureId}`);
        setStructure(result);
      } catch (err) {
        console.error('Error in read diagnosis: ', err);
        return enqueueSnackbar(
          `Si è verificato un errore nella lettura dell'ispezione`,
          {
            variant: 'error',
          }
        );
      } finally {
        hideLoader();
      }
    };
    handleStructureDetail();
  }, [collaborators, openSendArchive]);

  /* END - Permissions logic */

  return {
    titlePage,
    structure,
    infoData,
    methods,
    handleSubmit,
    onSubmit,
    collaborators,
    shareInspection,
    canShare,
    isStructureOwner,
    isInspectionOwner,
    formState,
    inspectionStatus,
    setWebCompletionPercentage,
    setAppCompletionPercentage,
    accessExpirationDate,
  };
};

export default useInspection;
