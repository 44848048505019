import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  InfoWrapper,
  MediaUpload,
  RHFCheckbox,
  RHFDatePicker,
  RHFSelect,
  RHFTextField,
} from 'components';
import {
  AnnoDiCostruzione,
  AnnoDiEntrataInEsercizio,
  AnnoDiProgettazione,
  AnnoInterventiSignificativiRicostruzione,
  ClasseProgetto,
  CriteriSismiciInOpera,
  DataApprossimativaAnnoDiCostruzione,
  DataApprossimativaAnnoDiProgettazione,
  DataApprossimativaInterventiSignificativiManutenzione,
  EffettivoPresunto,
  FieldType,
  InterventiSignificativiManutenzione,
  MediaGroup,
  NormaProgetto,
  UploadMedia,
} from 'constants/inspections';

import { useInspectionPath } from 'hooks';
import { useInspectionSelector } from 'stores';
import {
  CheckboxContainer,
  GridWrap,
  TitleSection,
} from './Accordion6DataProject.styles';
import { INFO } from '../../../../config/info';

const Accordion6DataProject = () => {
  const { getValues, setValue, watch } = useFormContext();
  const { inspectionDisabled } = useInspectionSelector();
  const { isViewingInspection } = useInspectionPath();

  const watchAnnoProgettazione = watch('annoProgettazione');
  const watchAnnoProgettazioneEffettivo =
    watchAnnoProgettazione === AnnoDiProgettazione.Effettivo;
  const watchAnnoProgettazioneApprossimativo =
    watchAnnoProgettazione === AnnoDiProgettazione.Approssimativo;

  const watchNormaProgetto = watch('normaProgetto');
  const watchNormaProgettoEffettivo = watch('normaProgettoEffettivo');
  const watchNormaProgettoPresunto = watch('normaProgettoPresunto');

  const watchtAnnoCostruzione = watch('annoCostruzione');
  const watchtAnnoCostruzioneEffettivo =
    watchtAnnoCostruzione === AnnoDiCostruzione.Effettivo;
  const watchtAnnoCostruzioneApprossimativo =
    watchtAnnoCostruzione === AnnoDiCostruzione.Approssimativo;

  const watchAnnoDiEntrataInEsercizio = watch('annoDiEntrataInEsercizio');
  const watchAnnoDiEntrataInEsercizioEffettivo =
    watchAnnoDiEntrataInEsercizio === AnnoDiEntrataInEsercizio.Effettivo;
  const watchAnnoDiEntrataInEsercizioApprossimativo =
    watchAnnoDiEntrataInEsercizio === AnnoDiEntrataInEsercizio.Approssimativo;

  const watchInterventiSignificativiManutenzione = watch(
    'interventiSignificativiManutenzione'
  );
  const watchInterventiSignificativiManutenzioneSi =
    watchInterventiSignificativiManutenzione ===
    InterventiSignificativiManutenzione.Si;

  const watchAnnoInterventiSignificativiRicostruzione = watch(
    'annoInterventiSignificativiRicostruzione'
  );
  const watchAannoInterventiSignificativiRicostruzioneEffettivo =
    watchAnnoInterventiSignificativiRicostruzione ===
    AnnoInterventiSignificativiRicostruzione.Effettivo;

  const watchAannoInterventiSignificativiRicostruzioneApprossimativo =
    watchAnnoInterventiSignificativiRicostruzione ===
    AnnoInterventiSignificativiRicostruzione.Approssimativo;

  /**
   * Start Effettivo Presunto
   */
  const watchCriteriSismiciEffettivo = watch('criteriSismiciEffettivo');
  const watchCriteriSismiciPresunto = watch('criteriSismiciPresunto');

  useEffect(() => {
    if (watchCriteriSismiciEffettivo) {
      setValue('criteriSismiciInOperaAltro', EffettivoPresunto.Effettivo);
    } else if (watchCriteriSismiciPresunto) {
      setValue('criteriSismiciInOperaAltro', EffettivoPresunto.Presunto);
    } else if (!watchCriteriSismiciEffettivo && !watchCriteriSismiciPresunto) {
      setValue('criteriSismiciInOperaAltro', null);
    }
  }, [watchCriteriSismiciEffettivo, watchCriteriSismiciPresunto]);

  /**
   * End Effettivo Presunto
   */

  //Functions
  useEffect(() => {
    !watchAnnoProgettazione &&
      setValue('dataApprossimativaAnnoDiProgettazione', '');

    !watchAnnoProgettazioneEffettivo
      ? setValue('dataEffettivaAnnoDiProgettazione', null)
      : setValue('dataApprossimativaAnnoDiProgettazione', '');

    const getDataEffettivaProgettazione = getValues(
      'dataEffettivaAnnoDiProgettazione'
    );

    const formatDataEffettiva = new Date(getDataEffettivaProgettazione);
    const getYearDataEffettiva = formatDataEffettiva.getFullYear();

    if (getDataEffettivaProgettazione !== null) {
      if (getYearDataEffettiva < 1952) {
        setValue(
          'dataApprossimativaAnnoDiProgettazione',
          DataApprossimativaAnnoDiProgettazione.Anno1952
        );
      }
      if (getYearDataEffettiva >= 1952 && getYearDataEffettiva < 1990) {
        setValue(
          'dataApprossimativaAnnoDiProgettazione',
          DataApprossimativaAnnoDiProgettazione.Anno19521990
        );
      }
      if (getYearDataEffettiva >= 1990 && getYearDataEffettiva < 2005) {
        setValue(
          'dataApprossimativaAnnoDiProgettazione',
          DataApprossimativaAnnoDiProgettazione.Anno19902005
        );
      }
      if (getYearDataEffettiva >= 2005 && getYearDataEffettiva < 2008) {
        setValue(
          'dataApprossimativaAnnoDiProgettazione',
          DataApprossimativaAnnoDiProgettazione.Anno20052008
        );
      }
      if (getYearDataEffettiva >= 2008 && getYearDataEffettiva !== null) {
        setValue(
          'dataApprossimativaAnnoDiProgettazione',
          DataApprossimativaAnnoDiProgettazione.AnnoOver2008
        );
      }
    }
  }, [watchAnnoProgettazione, watch('dataEffettivaAnnoDiProgettazione')]);

  useEffect(() => {
    // if (!watchtAnnoCostruzioneEffettivo) {
    //   setValue('dataEffettivaAnnoDiCostruzione', null);
    // } else {
    //   setValue('annoDiCostruzioneApprossimativo', '');
    // }

    !watchtAnnoCostruzione && setValue('annoDiCostruzioneApprossimativo', '');
    !watchtAnnoCostruzioneEffettivo
      ? setValue('dataEffettivaAnnoDiCostruzione', null)
      : setValue('annoDiCostruzioneApprossimativo', '');

    const getDataEffettivaCostruzione = getValues(
      'dataEffettivaAnnoDiCostruzione'
    );

    const formatDataEffettiva = new Date(getDataEffettivaCostruzione);
    const getYearDataEffettiva = formatDataEffettiva.getFullYear();

    if (getDataEffettivaCostruzione !== null) {
      if (getYearDataEffettiva <= 1945) {
        setValue(
          'annoDiCostruzioneApprossimativo',
          DataApprossimativaAnnoDiCostruzione.Anno1945
        );
      }
      if (getYearDataEffettiva > 1945 && getYearDataEffettiva < 1980) {
        setValue(
          'annoDiCostruzioneApprossimativo',
          DataApprossimativaAnnoDiCostruzione.Anno19451980
        );
      }

      if (getYearDataEffettiva >= 1980 && getYearDataEffettiva !== null) {
        setValue(
          'annoDiCostruzioneApprossimativo',
          DataApprossimativaAnnoDiCostruzione.AnnoOver1980
        );
      }
    }
  }, [watchtAnnoCostruzione, watch('dataEffettivaAnnoDiCostruzione')]);

  useEffect(() => {
    // if (!watchAannoInterventiSignificativiRicostruzioneEffettivo) {
    //   setValue('interventiSignificativiDataEffettiva', null);
    // }

    !watchAannoInterventiSignificativiRicostruzioneEffettivo
      ? setValue('interventiSignificativiDataEffettiva', null)
      : setValue('interventiSignificativiDataApprossimativa', '');

    const getDataEffettivaInterventi = getValues(
      'interventiSignificativiDataEffettiva'
    );

    const formatDataEffettiva = new Date(getDataEffettivaInterventi);
    const getYearDataEffettiva = formatDataEffettiva.getFullYear();

    if (getDataEffettivaInterventi !== null) {
      if (getYearDataEffettiva <= 1945) {
        setValue(
          'interventiSignificativiDataApprossimativa',
          DataApprossimativaInterventiSignificativiManutenzione.Anno1945
        );
      }
      if (getYearDataEffettiva > 1945 && getYearDataEffettiva < 1980) {
        setValue(
          'interventiSignificativiDataApprossimativa',
          DataApprossimativaInterventiSignificativiManutenzione.Anno19451980
        );
      }

      if (getYearDataEffettiva >= 1980 && getYearDataEffettiva !== null) {
        setValue(
          'interventiSignificativiDataApprossimativa',
          DataApprossimativaInterventiSignificativiManutenzione.AnnoOver1980
        );
      }
    }
  }, [
    watchAnnoInterventiSignificativiRicostruzione,
    watch('interventiSignificativiDataEffettiva'),
  ]);

  /* useEffect(() => {
    if (!watchNormaProgettoEffettivo) {
      setValue('dataPubblicazioneNorma', null);
    }
  }, [watchNormaProgettoEffettivo]);
 */
  useEffect(() => {
    if (
      !watchAnnoDiEntrataInEsercizioEffettivo &&
      !watchAnnoDiEntrataInEsercizioApprossimativo
    ) {
      setValue('dataDiEntrataInEsercizio', null);
    }
  }, [watchAnnoDiEntrataInEsercizio]);

  useEffect(() => {
    if (!watchInterventiSignificativiManutenzioneSi) {
      setValue('annoInterventiSignificativiRicostruzione', '');
      setValue('interventiSignificativiDataApprossimativa', '');
      setValue('interventiSignificativiDataEffettiva', null);
      // setValue('interventiSignificativiDataApprossimativa', '');
      // setValue('criteriSismiciEffettivo', '');
      // setValue('criteriSismiciPresunto', '');
    }
  }, [watchInterventiSignificativiManutenzione]);

  useEffect(() => {
    if (watchNormaProgetto !== ClasseProgetto.ClasseC) {
      setValue('vulnerabilitaStrutturaleBassa', '');
    }
  }, [watchNormaProgetto]);

  useEffect(() => {
    watchNormaProgettoPresunto && setValue('dataPubblicazioneNorma', '');
  }, [watchNormaProgettoPresunto]);

  return (
    <>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={16} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">DATI GENERALI</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="progettista"
            label={'Progettista'}
            inputProps={{ maxLength: 150 }}
            disabled={inspectionDisabled || isViewingInspection}
            defaultValue=""
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="annoProgettazione"
            label={'Anno di progettazione'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Effettivo',
                value: AnnoDiProgettazione.Effettivo,
              },
              {
                label: 'Approssimativo',
                value: AnnoDiProgettazione.Approssimativo,
              },
            ]}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFDatePicker
            label="Data effettiva"
            name="dataEffettivaAnnoDiProgettazione"
            inputFormat="yyyy"
            views={['year']}
            disabled={
              !watchAnnoProgettazioneEffettivo ||
              inspectionDisabled ||
              isViewingInspection
            }
            defaultValue={null}
            minDate={new Date(1500, 0, 1)}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="dataApprossimativaAnnoDiProgettazione"
            label={'Data approssimativa'}
            defaultValue={''}
            disabled={
              !watchAnnoProgettazioneApprossimativo ||
              inspectionDisabled ||
              isViewingInspection
            }
            selectOptions={[
              {
                label: 'Anno<1952',
                value: DataApprossimativaAnnoDiProgettazione.Anno1952,
              },
              {
                label: '1952≤Anno<1990',
                value: DataApprossimativaAnnoDiProgettazione.Anno19521990,
              },
              {
                label: '1990≤Anno<2005',
                value: DataApprossimativaAnnoDiProgettazione.Anno19902005,
              },
              {
                label: '2005≤Anno<2008',
                value: DataApprossimativaAnnoDiProgettazione.Anno20052008,
              },
              {
                label: 'Anno≥2008',
                value: DataApprossimativaAnnoDiProgettazione.AnnoOver2008,
              },
            ]}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="dataPubblicazioneNorma"
            label={'Norma di progetto'}
            defaultValue={''}
            disabled={
              watchNormaProgettoPresunto ||
              inspectionDisabled ||
              isViewingInspection
            }
            selectOptions={[
              {
                label: 'Antecedente al 1933',
                value: NormaProgetto.Antecedente1933,
              },
              {
                label: 'Normale N.8 1933',
                value: NormaProgetto.Normale,
              },
              {
                label: 'Circ. 6018/1945',
                value: NormaProgetto.Circ6018,
              },
              {
                label: 'Circ. 772/1946',
                value: NormaProgetto.Circ772,
              },
              {
                label: 'Circ. 820/1952',
                value: NormaProgetto.Circ820,
              },
              {
                label: 'Circ. 384/1962',
                value: NormaProgetto.Circ384,
              },
              {
                label: 'D.M. 1980',
                value: NormaProgetto.DM1980,
              },
              {
                label: 'D.M. 1990',
                value: NormaProgetto.DM1990,
              },
              {
                label: 'NTC2005',
                value: NormaProgetto.NTC2005,
              },
              {
                label: 'NTC2008',
                value: NormaProgetto.NTC2008,
              },
              {
                label: 'NTC2018',
                value: NormaProgetto.NTC2018,
              },
            ]}
          />
          {/*  <RHFDatePicker
            label="Data di pubblicazione norma"
            name="dataPubblicazioneNorma"
            inputFormat="yyyy"
            views={['year']}
            disabled={!watchNormaProgettoEffettivo}
          /> */}
        </GridWrap>

        <GridWrap item xs={3}>
          <CheckboxContainer>
            <RHFCheckbox
              name="normaProgettoEffettivo"
              label="Effettivo"
              disabled={
                watchNormaProgettoPresunto ||
                inspectionDisabled ||
                isViewingInspection
              }
              defaultValue={false}
            />
            <RHFCheckbox
              name="normaProgettoPresunto"
              label="Presunto"
              disabled={
                watchNormaProgettoEffettivo ||
                inspectionDisabled ||
                isViewingInspection
              }
              defaultValue={false}
            />
          </CheckboxContainer>
        </GridWrap>

        <InfoWrapper
          size={3}
          infoDrawer={true}
          drawerTitle={INFO.normaProgetto.drawerTitle}
          drawerText={INFO.normaProgetto.drawerText}
          drawerImages={INFO.normaProgetto.drawerImages}
        >
          <RHFSelect
            name="normaProgetto"
            label={'Classe di progettazione*'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Classe A',
                value: ClasseProgetto.ClasseA,
              },
              {
                label: 'Classe B',
                value: ClasseProgetto.ClasseB,
              },
              {
                label: 'Classe C',
                value: ClasseProgetto.ClasseC,
              },
            ]}
          />
        </InfoWrapper>

        <GridWrap item xs={3} />

        <InfoWrapper size={3} infoMessage={INFO.annoDiCostruzione.infoMessage}>
          <RHFSelect
            name="annoCostruzione"
            label={'Anno di costruzione'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Effettivo',
                value: AnnoDiCostruzione.Effettivo,
              },
              {
                label: 'Approssimativo',
                value: AnnoDiCostruzione.Approssimativo,
              },
            ]}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFDatePicker
            label="Data effettiva"
            name="dataEffettivaAnnoDiCostruzione"
            inputFormat="yyyy"
            views={['year']}
            disabled={
              !watchtAnnoCostruzioneEffettivo ||
              inspectionDisabled ||
              isViewingInspection
            }
            minDate={new Date(1500, 0, 1)}
          />
        </GridWrap>

        <GridWrap item xs={3}>
          <RHFSelect
            name="annoDiCostruzioneApprossimativo"
            label={'Data approssimativa*'}
            defaultValue={''}
            disabled={
              !watchtAnnoCostruzioneApprossimativo ||
              inspectionDisabled ||
              isViewingInspection
            }
            selectOptions={[
              {
                label: '≤1945',
                value: DataApprossimativaAnnoDiCostruzione.Anno1945,
              },
              {
                label: '1945-1980',
                value: DataApprossimativaAnnoDiCostruzione.Anno19451980,
              },
              {
                label: '≥1980',
                value: DataApprossimativaAnnoDiCostruzione.AnnoOver1980,
              },
            ]}
          />
        </GridWrap>
        <GridWrap item xs={3} />
        <GridWrap item xs={3}>
          <RHFSelect
            name="annoDiEntrataInEsercizio"
            label={'Anno di entrata in esercizio'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Effettivo',
                value: AnnoDiEntrataInEsercizio.Effettivo,
              },
              {
                label: 'Approssimativo',
                value: AnnoDiEntrataInEsercizio.Approssimativo,
              },
              {
                label: 'Non noto',
                value: AnnoDiEntrataInEsercizio.NonNoto,
              },
            ]}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFDatePicker
            label="Data di entrata in esercizio"
            name="dataDiEntrataInEsercizio"
            inputFormat="yyyy"
            views={['year']}
            defaultValue={null}
            disabled={
              (!watchAnnoDiEntrataInEsercizioEffettivo &&
                !watchAnnoDiEntrataInEsercizioApprossimativo) ||
              inspectionDisabled ||
              isViewingInspection
            }
            minDate={new Date(1500, 0, 1)}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFTextField
            name="enteApprovatore"
            label={'Ente approvatore'}
            inputProps={{ maxLength: 150 }}
            disabled={inspectionDisabled || isViewingInspection}
            defaultValue=""
          />
        </GridWrap>
        <GridWrap item xs={3} />
        <InfoWrapper
          size={3}
          infoMessage={INFO.interventiSignificativiManutenzione.infoMessage}
          legislationMessage={
            INFO.interventiSignificativiManutenzione.legislationMessage
          }
        >
          <RHFSelect
            name="interventiSignificativiManutenzione"
            label={'Interventi significativi di manutenzione*'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Sì',
                value: InterventiSignificativiManutenzione.Si,
              },
              {
                label: 'No',
                value: InterventiSignificativiManutenzione.No,
              },
            ]}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <RHFSelect
            name="annoInterventiSignificativiRicostruzione"
            label={'Anno interventi significativi di ricostruzione'}
            defaultValue={''}
            disabled={
              !watchInterventiSignificativiManutenzioneSi ||
              inspectionDisabled ||
              isViewingInspection
            }
            selectOptions={[
              {
                label: 'Effettivo',
                value: AnnoInterventiSignificativiRicostruzione.Effettivo,
              },
              {
                label: 'Approssimativo',
                value: AnnoInterventiSignificativiRicostruzione.Approssimativo,
              },
            ]}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFDatePicker
            label="Data effettiva"
            name="interventiSignificativiDataEffettiva"
            inputFormat="yyyy"
            views={['year']}
            disabled={
              !watchInterventiSignificativiManutenzioneSi ||
              !watchAannoInterventiSignificativiRicostruzioneEffettivo ||
              inspectionDisabled ||
              isViewingInspection
            }
            minDate={new Date(1500, 0, 1)}
          />
        </GridWrap>
        <GridWrap item xs={3}>
          <RHFSelect
            name="interventiSignificativiDataApprossimativa"
            label={'Data approssimativa*'}
            defaultValue={''}
            disabled={
              !watchAannoInterventiSignificativiRicostruzioneApprossimativo ||
              inspectionDisabled ||
              isViewingInspection
            }
            selectOptions={[
              {
                label: '≤1945',
                value:
                  DataApprossimativaInterventiSignificativiManutenzione.Anno1945,
              },
              {
                label: '1945-1980',
                value:
                  DataApprossimativaInterventiSignificativiManutenzione.Anno19451980,
              },
              {
                label: '≥1980',
                value:
                  DataApprossimativaInterventiSignificativiManutenzione.AnnoOver1980,
              },
            ]}
          />
        </GridWrap>
        <InfoWrapper
          size={3}
          infoDrawer={true}
          drawerTitle={INFO.criteriAntiSismiciInOpera.drawerTitle}
          drawerText={INFO.criteriAntiSismiciInOpera.drawerText}
          infoMessage={INFO.criteriAntiSismiciInOpera.infoMessage}
          legislationMessage={INFO.criteriAntiSismiciInOpera.legislationMessage}
        >
          <RHFSelect
            name="criteriSismiciInOpera"
            label={'Criteri antisismici in opera*'}
            defaultValue={''}
            disabled={inspectionDisabled || isViewingInspection}
            selectOptions={[
              {
                label: 'Sì',
                value: CriteriSismiciInOpera.Si,
              },
              {
                label: 'No',
                value: CriteriSismiciInOpera.No,
              },
            ]}
          />
        </InfoWrapper>
        <GridWrap item xs={3}>
          <CheckboxContainer>
            <RHFCheckbox
              name="criteriSismiciEffettivo"
              label="Effettivo"
              disabled={
                watchCriteriSismiciPresunto ||
                inspectionDisabled ||
                isViewingInspection
              }
            />
            <RHFCheckbox
              name="criteriSismiciPresunto"
              label="Presunto"
              disabled={
                watchCriteriSismiciEffettivo ||
                inspectionDisabled ||
                isViewingInspection
              }
            />
          </CheckboxContainer>
        </GridWrap>
      </GridWrap>
      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              DOCUMENTAZIONE INIZIALE
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Strumenti finanziamento`}
            btnTitle="Allega file"
            formName="strumentiFinanziamento"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Piani e strumento di programmazione`}
            btnTitle="Allega file"
            formName="pianiStrumentiProgrammazione"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              PROGETTO PRELIMINARE/DI MASSIMA
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti descrittivi`}
            btnTitle="Allega file"
            formName="documentiDescrittivi"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti grafici`}
            btnTitle="Allega file"
            formName="documentiGrafici"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
            ]}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti inerenti l'iter di approvazione`}
            btnTitle="Allega file"
            formName="documentiIterApprovazione"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              PROGETTO DEFINITIVO/ESECUTIVO
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti descrittivi`}
            btnTitle="Allega file"
            formName="documentiDescrittiviDefinitivo"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti grafici`}
            btnTitle="Allega file"
            formName="documentiGraficiDefinitivo"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
            ]}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti inerenti l'iter di approvazione`}
            btnTitle="Allega file"
            formName="documentiIterApprovazioneDefinitivo"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              PROGETTO ESECUTIVO/CANTIERABILE
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti descrittivi`}
            btnTitle="Allega file"
            formName="documentiDescrittiviCantinerabile"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti grafici`}
            btnTitle="Allega file"
            formName="documentiGraficiCantinerabile"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
            ]}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti inerenti l'iter di approvazione`}
            btnTitle="Allega file"
            formName="documentiIterApprovazioneCantinerabile"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">DIREZIONE LAVORI</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti contabili`}
            btnTitle="Allega file"
            formName="documentiContabili"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Grafici allegati alla contabilità dell'appalto`}
            btnTitle="Allega file"
            formName="graficiAllegatiContabilitaAppalto"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">REALIZZAZIONE</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti contrattuali`}
            btnTitle="Allega file"
            formName="documentiContrattuali"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti contabili`}
            btnTitle="Allega file"
            formName="documentiContabiliRealizzazione"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">
              VARIANTI IN CORSO D&apos;OPERA
            </Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti descrittivi`}
            btnTitle="Allega file"
            formName="documentiDescrittiviVarianti"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti grafici`}
            btnTitle="Allega file"
            formName="documentiGraficiVarianti"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
            ]}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Documenti contrattuali e contabili`}
            btnTitle="Allega file"
            formName="documentiContrattualiContabili"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
      </GridWrap>

      <GridWrap container spacing={2} padding="20px">
        <GridWrap marginBottom={-25} marginTop={35} item xs={12}>
          <TitleSection>
            <Typography variant="h3BoldBlue">COLLAUDO</Typography>
          </TitleSection>
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Relazione di collaudo`}
            btnTitle="Allega file"
            formName="relazioneCollaudo"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
        <GridWrap item md={3}>
          <MediaUpload
            title={`Allegati alla relazione di collaudo`}
            btnTitle="Allega file"
            formName="allegatiRelazioneCollaudo"
            dataType=".pdf,.doc,.docx,.jpeg,.png,.dwg"
            uploadType={UploadMedia.documents}
            type={FieldType.documents}
            mediaGroup={[MediaGroup.Progetto]}
            tags={[
              'amministrativo',
              'architettonico',
              'strutturale',
              'impianti',
              'progettista',
              'collaudatore',
              'certificato',
              'computi',
              'corrispondenza',
              'contratti',
              'nomine',
              'incarichi',
              'contabilità',
              'fornitori',
              'preventivi',
            ]}
          />
        </GridWrap>
      </GridWrap>
    </>
  );
};

export { Accordion6DataProject };
